import * as React from "react";
import { Link } from "gatsby";
import Seo from "../components/seo";

const NotFoundPage = ({ data }) => {
  return (
    <main>
      <div id="notFoundOuter">
        <Seo title="Page Not Found" />
        <h1>Page Not Found</h1>
        <p>
          お探しのページは見つかりませんでした。<br />
          下記のリンクよりトップページに移動して下さい。
        </p>
        <p>
          <Link to="/">映画「いのちめぐる島 イリオモテ」公式サイト</Link>
        </p>
      </div>
    </main>
  );
}

export default NotFoundPage;
